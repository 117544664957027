export const environment = {
  production: true,
  api: {
    url: 'https://staging.emea.api.amplifoninternal.com',
    key: 'l7efKQKMyb1NQFdirbtFb1wAw4pJn2qq2V7m7XKx'
  },
  sso: {
    login: 'https://ubiquity-test.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=AzureAD&response_type=token&client_id=400lg9r8au9s8cb6cieaeq0qtn&scope=openid&redirect_uri=https://portaltest.ubiquity.amplifon.com/',
    logout: 'https://ubiquity-test.auth.eu-central-1.amazoncognito.com/logout?client_id=400lg9r8au9s8cb6cieaeq0qtn&logout_uri=https://portaltest.ubiquity.amplifon.com'
  }
};
